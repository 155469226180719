import React from "react"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const pagoExitoD = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title="Pago Exitoso"
        titleTemplate="%s | Eduardo Ceballos"
        noindex={true}
        nofollow={true}
      />

      <h1>Pago Exitoso</h1>

      <p>
        Su pago se realizó con éxito, le estaremos enviando a la brevedad un
        correo con el libro adjunto como PDF al igual que un enlace permanente
        desde el cual descargar el libro.
      </p>
    </Layout>
  )
}

export default pagoExitoD
